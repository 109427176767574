import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertFilterOptions, AlertService } from '../../services/alert.service';
import { BehaviorSubject, Subscription, debounceTime, finalize } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { AppConstant } from '../../utilities/app.constant';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ALERT_EXPORT_FILTER } from '../../metadata/alert-export.metadata';
import { isEmpty } from 'lodash';
import { Alert } from '../../interface/alert.interface';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';

import autoTable from 'jspdf-autotable';
import { formatDateTime, formatHierarchyString } from './temp-helper'
import { AppHelper } from '../../utilities/app.helper';
import { UNIT_SYSTEM } from '../../utilities/app.helper.data';

@Component({
  selector: 'app-export-setting',
  templateUrl: './export-setting.component.html',
  styleUrls: ['./export-setting.component.scss'],
})
export class ExportSettingComponent implements OnInit, OnDestroy {

  minDateValue: Date = new Date(-8640000000000000);
  maxDateValue: Date = new Date(+8640000000000000);
  defaultDateValue: Date = new Date();
  selectableDate: string = 'Selectable value is in the range: All';
  
  formGroupExport: any;
  alertListData: Alert[] = [];

  formatOptions: { name: string }[] = [{ name: 'XLSX' }, { name: 'PDF' }];
  alertTypeOptions: any = []
  statusesOption: any = [];
  eventsOption: any = [];
  disciplineOptions: { name: string }[] = [];
  wellboreOptions: { id:string, name: string }[] = [];
  intervalOptions: { id:string, name: string }[] = [];
  runOptions: { id:string, name: string }[] = [];

  dataFields: { id:string, name: string }[] = [
    { id: 'AlertType', name: 'Alert Type' },
    { id: 'Initiated', name: 'Initiated' },
    { id: 'InitiatedBy', name: 'Initiated By' },
    { id: 'Resolved', name: 'Resolved' },
    { id: 'ResolvedBy', name: 'Resolved By' },
    { id: 'Status', name: 'Status' },
    { id: 'Event', name: 'Event' },
    { id: 'EventDescription', name: 'Event Description' },
    { id: 'Recommendation', name: 'Recommendation' },
    { id: 'Risks', name: 'Risks' },
    { id: 'BitDepth', name: 'Bit Depth' },
    { id: 'HoleDepth', name: 'Hole Depth' },
    { id: 'Resolution', name: 'Resolution' },
    { id: 'TimeSaved', name: 'Time Saved' },
    { id: 'Run', name: 'Run' },
    { id: 'Interval', name: 'Interval' },
    { id: 'HoleSize', name: 'Hole Size' },
    { id: 'Well', name: 'Well' },
    { id: 'Wellbore', name: 'Wellbore' },
    { id: 'Project', name: 'Project' },
    { id: 'RemoteCenter', name: 'Remote Center' },
    { id: 'Region', name: 'Region' },
    { id: 'Rig', name: 'Rig' },
  ];

  selectedDataFields: any[] = [];

  METADATA: any;

  alertTypeCount: number = 0;
  statusCount: number = 0;
  eventCount: number = 0;
  disciplineCount: number = 0;
  wellboreCount: number = 0;
  intervalCount: number = 0;
  runCount: number = 0;
  dataFieldsCount: number = 23;
  rigJournalId: string = '';
  user: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  private apiAlertTableSubscription: Subscription = new Subscription();
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  ngOnInit() {
    if (this.dialogConfig.data.rigJournalId) {
      this.rigJournalId = this.dialogConfig.data.rigJournalId;
      
      this.isLoading$.next(true);
      this.METADATA = ALERT_EXPORT_FILTER;

      this.loadInitialData(this.dialogConfig.data.rigJournalId);
      this.buildForm();
    } else {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Information',
        content: 'Can not find rig journal id',
      });
    }
  }

  loadInitialData(rigJournalId: string) {
    this.apiAlertTableSubscription = this.alertService
      .getAlertFilterOptionsbyId(rigJournalId)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        })
      )
      .subscribe({
        next: (data: any) => {
          const { timeRange, alertType, status, events, disciplines, wellbores, intervals, runs } = data;

          // Setting for Time Range Dropdowns
          const [smallest, largest] = timeRange;
          if (smallest && largest) {
            this.minDateValue = new Date(smallest);
            this.maxDateValue = new Date(largest);
            this.selectableDate = `Selectable value is in the range: ${formatDateTime(
              new Date(smallest),
              false
            )} to ${formatDateTime(
              new Date(largest),
              false
            )}`;
          }
          
          // prepare Alert Type options
          this.alertTypeOptions = (alertType).map((item: any) => ({
            id: item.eventType,
            name: item.eventType.charAt(0).toUpperCase() + item.eventType.slice(1)
          }));

          // prepare Status options
          this.statusesOption = (status).map((item: any) => ({
            stateCode: item.id,
            statusName: item.name
          }));

          // Prepare Event option
          this.eventsOption = events;

          const listEventDataId: string[] = [];
          this.eventsOption = [];
          events.forEach((event: any) => {
            if (event?.event_id)
              listEventDataId.push(event.event_id);
          });
          AppConstant.EVENTS.map((nodeEvent: any) => {
            const dataTree = this.makeEventOption(listEventDataId, nodeEvent);        
            if (dataTree)
              this.eventsOption.push(dataTree);
          });

          // Prepare Dsciplines option
          this.disciplineOptions = (disciplines).map((item: any) => ({
            disciplineName: item
          }));

          // Prepare Wellbore option
          this.wellboreOptions = (wellbores).map((item: any) => ({
            id: item.wellboreId,
            name: item.wellboreCurrentName || item.wellboreOriginalName
          })); 

          // Prepare Interval option
          this.intervalOptions = (intervals).map((item: any) => ({
            id: item.intervalId,
            name: item.intervalName
          }));

          this.intervalOptions.push({
            id: 'n/a',
            name: 'N/A'
          })

          // Prepare Run option
          this.runOptions = (runs).map((item: any) => ({
            id: item.runId,
            name: `${item.description} (${item.runNo})`,
          })); 

          this.runOptions.push({
            id: 'n/a',
            name: 'N/A',
          })
        },
        error: (err: any) => {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: err?.error,
          });
        },
      });
  }

  buildForm() {
    const dataFieldGroup: any = {};
    this.dataFields.forEach((checkbox: any) => {
      dataFieldGroup[checkbox.id] = new FormControl(true);
    });

    this.formGroupExport = this.formBuilder.group({
      timeRange: null,
      alertType: null, 
      status: null,
      event: null,
      discipline: null,
      wellbore: null,
      interval: null,
      run: null,
      dataFields: this.formBuilder.group(dataFieldGroup, { validators: this.validateCheckboxes }),
      format: this.formatOptions[0]
    });

    // Listen for changes in the form controls
    this.formGroupExport.valueChanges
      .pipe(debounceTime(100))
      .subscribe((value: any) => {
        // Count the selected checkboxes
        this.alertTypeCount = value.alertType && Object.values(value.alertType).filter(val => val).length;
        this.statusCount = value.status && Object.values(value.status).filter(val => val).length;
        this.eventCount = value.event && Object.values(value.event).filter(val => val).length;
        this.disciplineCount = value.discipline && Object.values(value.discipline).filter(val => val).length;
        this.wellboreCount = value.wellbore && Object.values(value.wellbore).filter(val => val).length;
        this.intervalCount = value.interval && Object.values(value.interval).filter(val => val).length;
        this.runCount = value.run && Object.values(value.run).filter(val => val).length;
        this.dataFieldsCount = value.dataFields && Object.values(value.dataFields).filter(val => val).length;
      });
  }

  clearDataFields() {
    const checkboxGroup = this.formGroupExport.get('dataFields');
    checkboxGroup.updateValueAndValidity();
    Object.keys(checkboxGroup.controls).forEach(key => {
      checkboxGroup.get(key).setValue(true);
    });
    checkboxGroup.markAsTouched();
    checkboxGroup.updateValueAndValidity();
  }

  onExport(): void {
    this.isLoading$.next(true);
    const rawValue = this.formGroupExport.getRawValue();
    const eventSelection = rawValue.event?.map((selection: any) => {
      return {
        data: selection?.selection || "",
        label: selection?.label || "",
        event_id: selection?.event_id || "",

      }
    }) || null;
    rawValue.event = eventSelection;

    let payloadFilter = JSON.parse(JSON.stringify(rawValue));
    
    // Change number date to mid night
    if (payloadFilter.timeRange && payloadFilter.timeRange[0]) {
      payloadFilter.timeRange[0] = AppHelper.DateFunctions.convertToMidnightTimestamp(payloadFilter.timeRange[0], false);
    }
    if (payloadFilter.timeRange && payloadFilter.timeRange[1]) {
      payloadFilter.timeRange[1] = AppHelper.DateFunctions.convertToMidnightTimestamp(payloadFilter.timeRange[1], true);
    }

    // Check if payloadFilter.timeRange have only one date value
    if (payloadFilter.timeRange && (payloadFilter.timeRange[0] === null || payloadFilter.timeRange[1] === null)) {
      const singleDate = payloadFilter.timeRange[0] || payloadFilter.timeRange[1];
      payloadFilter.timeRange[0] = AppHelper.DateFunctions.convertToMidnightTimestamp(singleDate, false);
      payloadFilter.timeRange[1] = AppHelper.DateFunctions.convertToMidnightTimestamp(singleDate, true);
    }

    const alterFilterOptions: AlertFilterOptions = {    
      pageSize: 999,
      pageIndex: 0,
    };

    // Missing 4 Key [TODO]
    !isEmpty(payloadFilter?.timeRange) && (alterFilterOptions.timeRange = payloadFilter.timeRange);
    !isEmpty(payloadFilter?.alertType) && (alterFilterOptions.alertType = payloadFilter.alertType);
    !isEmpty(payloadFilter?.status) && (alterFilterOptions.status = payloadFilter.status);
    !isEmpty(payloadFilter?.event) && (alterFilterOptions.event = payloadFilter.event.map((item: any)=> item.event_id));
    !isEmpty(payloadFilter?.discipline) && (alterFilterOptions.discipline = payloadFilter.discipline.map((item: any)=> item.disciplineName));
    !isEmpty(payloadFilter?.wellbore) && (alterFilterOptions.wellbore = payloadFilter.wellbore.map((item: any)=> item.id));
    !isEmpty(payloadFilter?.interval) && (alterFilterOptions.interval = payloadFilter.interval.map((item: any)=> item.id));
    !isEmpty(payloadFilter?.run) && (alterFilterOptions.run = payloadFilter.run.map((item: any)=> item.id));

    this.alertService.getAlertListV2(alterFilterOptions, this.rigJournalId).subscribe({
      next: (paginatedAlerts: any) => {
        this.alertListData = paginatedAlerts.items;

        if (this.alertListData.length === 0) {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Alert Export Information',
            content: 'There are no Alerts to export',
          });
          this.isLoading$.next(false);
          return;
        }
    
        if (this.formGroupExport.value.format.name === 'XLSX') {
          this.onExportExcel(this.alertListData, payloadFilter.dataFields);
        }
    
        if (this.formGroupExport.value.format.name === 'PDF') {
          this.onExportPDF(this.alertListData, payloadFilter.dataFields);
        }
    
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  onExportExcel(alertListData: Alert[], dataField: any): boolean {
    const exportData: any[] = this.collectDataHeader(alertListData, dataField);
    let worksheet: any = utils.json_to_sheet(exportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    return this.saveAsExcelFile(excelBuffer);
  }

  onExportPDF(alertListData: Alert[], dataField: any): boolean {
    let PDF_EXTENSION = '.pdf';
    const exportData: any[] = this.collectDataHeader(alertListData, dataField);
    const doc = this.dataFieldsCount > 8 ? new jsPDF('l', 'mm', 'a1') : new jsPDF('p', 'mm', 'a2');

    doc.addFont('../../../../assets/fonts/Barlow-Medium.ttf', 'Barlow-Medium', 'normal');

    if (exportData.length) {
      const column: string[] = ['No'];
      const tableData: any[] = [];
      for (let col in exportData[0]) {
        if (col !== 'PDF') {
          column.push(col);
        }
      }

      exportData.forEach((row: any, index: number) => {
        const numberRow = (index + 1).toString();
        const currentRow = [numberRow];
        for (let key in row) {
          currentRow.push(row[key]);
        }
        tableData.push(currentRow);
      });

      autoTable(doc, {
        head: [column],
        body: tableData,
        columnStyles: {
          0: { cellWidth: 8 },
        },
        bodyStyles: {
          font: 'Barlow-Medium',
          minCellWidth: 28,
          cellWidth: 'auto'
        },
      });

      doc.save(
        'Report_Alert_Management_export_' +
        AppHelper.DateFunctions.formatDate(new Date(), 'yyyyMMddHHmmssfff') +
        PDF_EXTENSION
      );
    }
    
    this.isLoading$.next(false);
    return true;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  validateCheckboxes(control: FormGroup): { [key: string]: any } | null {
    const selected = Object.values(control.value).some(val => val);
    return selected ? null : { 'noSelection': true };
  }

  makeEventOption(arrayRisksId: string[], node: any) {
    if (!node) return null;

    if (node?.event_id) {
      if (arrayRisksId.includes(node.event_id)) {
        return node;
      }
      return null;
    }

    const nodeClonde: any = {
      data: node.data, 
      label: node.label,
      children: [],
      event_id: node?.event_id,
      eventType: node?.eventType,
      leaf: node?.leaf,
      styleClass: node?.styleClass,
    }

    for (let index = 0; index < node.children.length; index++) {
      const data = this.makeEventOption(arrayRisksId, node.children[index]);
      if (data) {
        nodeClonde.children.push(data);
      }
    }

    if (nodeClonde.children.length > 0)
      return nodeClonde;
    else return null;
  }

  saveAsExcelFile(buffer: any): boolean {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs.saveAs(
      data,
      'Report_Alert_Management_export_' +
      AppHelper.DateFunctions.formatDate(new Date(), 'yyyyMMddHHmmssfff') +
      EXCEL_EXTENSION
    );

    this.isLoading$.next(false);
    return true;
  }

  collectDataHeader(alertListData: Alert[], dataField: any): any[] {
    let rawData = JSON.parse(JSON.stringify(alertListData));
    rawData = rawData.map((item: any) => ({
      'Alert Type': item.eventType.charAt(0).toUpperCase() + item.eventType.slice(1),
      Initiated: item.initiated
        ? formatDateTime(
            new Date(Number(new Date(item.initiated))), true
          )
        : 'N/A',
      'Initiated By': this.getInitiatedBy(item.initiated, item.auditTrail),
      Resolved: item.resolved
        ? formatDateTime(
            new Date(Number(new Date(item.resolved))), true
          )
        : 'N/A',
      'Resolved By': this.getResolvedBy(item.resolved, item.auditTrail),
      Status: item.currentState,
      Event: formatHierarchyString(item.event),
      'Event Description': item.eventDescription,
      Recommendation: item.recommendation || 'N/A',
      Risks: AppHelper.StringFunctions.formatRiskString(item.risk),
      'Bit Depth': item.bitMD ?
        AppHelper.MathFunctions.transferData(
          item.bitMD,
          UNIT_SYSTEM,
          item.additionalData.project.curUnit,
          item.unit ? item.unit : '',
          'depth',
          0,
          0,
          0,
          0,
          true
        ) : 'N/A',
      'Hole Depth': item.holeMD ?
        AppHelper.MathFunctions.transferData(
          item.holeMD,
          UNIT_SYSTEM,
          item.additionalData.project.curUnit,
          item.unit ? item.unit : '',
          'depth',
          0,
          0,
          0,
          0,
          true
        ) : 'N/A',
      Resolution: item.resolution || 'N/A',
      'Time Saved': item.timeSaved ? item.timeSaved + 'h' : 'N/A',
      Run: item.run ? `${item.run.description} (${item.run.runNo})`: 'N/A',
      Interval: !!item.interval ? item.interval.intervalName : 'N/A',
      'Hole Size': item.interval ? 
        AppHelper.MathFunctions.transferData(
          item.interval.openHoleDiameter,
          UNIT_SYSTEM,
          item.additionalData.project.curUnit,
          item.unit ? item.unit : '',
          'diameter',
          0,
          0,
          0,
          0,
          true
        )
      : 'N/A',
      Well: `${item.additionalData.well.wellCurrentName || item.additionalData.well.wellOriginalName}`,
      Wellbore: `${item.additionalData.wellbore.wellboreCurrentName || item.additionalData.wellbore.wellboreOriginalName}`,
      Project: item.additionalData.project.projectCurrentName || item.additionalData.project.projectOriginalName,
      'Remote Center': item.remoteCenter,
      Region: item.additionalData.project.region || 'N/A',
      Rig: `${item.additionalData.rig.rigName} (${item.additionalData.contractor.contractorName})`,
    }));

    const fieldsSelect = this.dataFields.filter((field: any) => dataField[field?.id]).map((field: any) => field.name);
    return rawData = rawData.map((item: any) => {
      const result: any = {};
      for (const key of fieldsSelect) {
        if (item.hasOwnProperty(key)) {
          result[key] = item[key];
        }
      }
      return result;
    });
  }

  getInitiatedBy(initiated: null | string, auditTrail: any) {
    if(!!initiated && auditTrail.length) {
      const initiatedTime = new Date(initiated).getTime();
      const auditTrailLog = auditTrail.find((log: any) => { 
        return new Date(log.timestamps).getTime() === initiatedTime;
      });
      return `${auditTrailLog.userDisplayName} (${auditTrailLog.userDiscipline})`
    }
    return 'N/A';
  }

  getResolvedBy(resolved: null | string, auditTrail: any) {
    if(!!resolved && auditTrail.length) {
      const initiatedTime = new Date(resolved).getTime();
      const auditTrailLog = auditTrail.find((log: any) => { 
        return new Date(log.timestamps).getTime() === initiatedTime;
      });
      return `${auditTrailLog.userDisplayName} (${auditTrailLog.userDiscipline})`
    }
    return 'N/A';
  }

  ngOnDestroy() {
    this.isLoading$.next(false);
    this.apiAlertTableSubscription.unsubscribe();
  }
}
