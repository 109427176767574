import { RouterModule, Routes } from '@angular/router';
import { CanActivateGuard } from './shared/guards/CanActivateGuard';
import { ErrorComponent } from './pages/error/error.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/guards/AuthGuard';
import { AppConstant } from './shared/utilities/app.constant';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    title: `${AppConstant.APPLICATION_NAME} - Login`,
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    title: `${AppConstant.APPLICATION_NAME} - Home Page`,
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'rig/rig-management',
    loadChildren: () => import('./pages/rig/rig.module').then(m => m.RigModule),
    title: `${AppConstant.APPLICATION_NAME} - Rig Management`,
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
  },
  {
    path: 'mailbox/mailbox-management',
    loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailBoxModule),
    title: `${AppConstant.APPLICATION_NAME} - Mailbox Management`,
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
  },
  {
    path: 'help-center',
    loadChildren: () => import('./pages/help-center/help-center.module').then(m => m.HelpVideoModule),
    title: `${AppConstant.APPLICATION_NAME} - Help Center`,
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'alerts/alerts-management',
    loadChildren: () => import('./pages/alert/alert.module').then(m => m.AlertModule),
    title: `${AppConstant.APPLICATION_NAME} - Alert Management`,
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // {
  //   path: 'dynamic-page',
  //   loadChildren: () => import('./pages/dynamic/dynamic.module').then(m => m.DynamicModule),
  //   title: `${AppConstant.APPLICATION_NAME} - Dynamic Content`,
  // },
  {
    path: '**',
    component: ErrorComponent,
    title: `${AppConstant.APPLICATION_NAME} - Not Found`,
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
