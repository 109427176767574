import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import axios from 'axios';
import { AppConstant } from '../utilities/app.constant';
import { UserInfoService } from './user-info.service';
import { at } from 'lodash';
import { AuthenticationProvider, Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import { AppHelper } from '../utilities/app.helper';
import { from, interval, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';

interface User {
  "@odata.type": string;
  id: string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string | null;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string | null;
  surname: string;
  userPrincipalName: string;
}

interface AadUserConversationMember {
  "@odata.type"?: string;
  id: string;
  roles: string[];
  displayName: string;
  visibleHistoryStartDateTime: string;
  userId: string;
  email: string;
  tenantId: string;
}

@Injectable({
  providedIn: 'root',
})
export class GraphApiService {
  GRAPH_SCOPE = 'https://graph.microsoft.com/.default';

  // 60 seconds for refresh token
  TIMER_REFRESH = 60000;
  accessToken = '';
  private intervalSubscription: Subscription;

  constructor(
    private _msalService: MsalService,
    private _userInfoService: UserInfoService,
  ) {
    this.intervalSubscription = this.setInterval().subscribe();
  }

  private setInterval(): Observable<any> {
    return interval(this.TIMER_REFRESH).pipe(
      tap(() => this.accessToken = '')
    )
  }

  private getGraphClient(accessToken: string): Client {
    const authProvider: AuthenticationProvider = {
      getAccessToken: async () => accessToken
    };

    return Client.initWithMiddleware({ authProvider });
  }

  ngOnDestroy() {
    // Clean up the subscription to prevent memory leaks
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private getTokenForScope(): Observable<any> {
    const accessTokenRequest = {
      scopes: [this.GRAPH_SCOPE]
    };
    const accessToken = this.accessToken;

    if (!accessToken) {
      return from(this._msalService.acquireTokenSilent(accessTokenRequest).pipe(
        tap((result) => this.accessToken = result.accessToken),
        map(result => result.accessToken)
      ));
    } else {
      return of(accessToken)
    }
  }

  public getListData(): Observable<any> {
    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        return Promise.all([
          this.getListChats(accessToken),
          this.getListChannel(accessToken)
        ]).then(([chats, channels]) => ({
          chats,
          channels
        }));
      }
    ))
  }

  private async getListChats(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const urlQuery = 'https://graph.microsoft.com/v1.0/me/chats?$filter=chatType eq \'group\' and topic ne null&$top=50';
    let nextLink = '';
    let groupResponse: any[] = [];

    do {
      const response = await axios.get(nextLink || urlQuery,
        { headers }
      );
      const listChat = response?.data?.value;
      groupResponse = groupResponse.concat(listChat.map((group: any) => ({
        id: group.id,
        groupId: group.id,
        label: group.topic
      })));
      nextLink = response?.data?.['@odata.nextLink'] || null;
    } while (nextLink);

    // validate user are in this list group, remove group if use are not in
    const listChatId = groupResponse.map((group: any) => (group.id));
    const promiseAll: Promise<any>[] = []
    listChatId.forEach(async (id: string) => {
      promiseAll.push(this.checkGroupChatExist(accessToken, id))
    })
    const responsePromise = await Promise.all(promiseAll);
    const result: any = {};
    responsePromise.forEach((item: any) => {
      result[item.id] = item;
    })
    groupResponse = groupResponse.filter((group: any) => result?.[`${group.id}`].status == AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED)
    
    return groupResponse;
  }

  private async getListChannel(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const teamsResponse = await axios.get(
      'https://graph.microsoft.com/v1.0/me/joinedTeams',
      { headers }
    );

    if (!teamsResponse?.data?.value) return [];

    const teams = teamsResponse.data.value;
    const promiseAllChannel: Promise<any>[] = [];
    const teamResponse: any[] = [];
    for (const team of teams) {
      const channelsResponse = axios.get(
        `https://graph.microsoft.com/v1.0/teams/${team.id}/channels`,
        { headers }
      );
      promiseAllChannel.push(channelsResponse);
      teamResponse.push({
        id: team.id,
        label: team.displayName,
        children: []
      })
    }

    const response = await Promise.all(promiseAllChannel);
    response.forEach((r: any) => {
      const listChannel = r.data.value.filter((channel: any) => channel?.webUrl);
      if (listChannel.length <= 0) return;

      const url = new URL(listChannel[0].webUrl);
      const urlParams = new URLSearchParams(url.search);
      const teamId = urlParams.get('groupId');

      listChannel.forEach((channel: any) => {
        const team = teamResponse.find((team: any) => team.id == teamId);
        team.children.push({
          id: channel.id,
          teamId: teamId,
          label: channel.displayName
        })
      })
    })

    return teamResponse;
  }

  public checkListGroupChatExist(chatIds: string[] = []): Observable<any> {
    if (chatIds.length == 0) return of([]);

    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        const promiseAll: Promise<any>[] = []
        chatIds.forEach(async (id: string) => {
          promiseAll.push(this.checkGroupChatExist(accessToken, id))
        })
        return Promise.all(promiseAll).then((res) => {
          const result: any = {};
          res.forEach((item) => {
            result[item.id] = item;
          })
          return result;
        });
      }
    ))
  }

  private async checkGroupChatExist(accessToken: string, chatId: string): Promise<any> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    try {
      const userId = this._userInfoService.userSubject.getValue().id;
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/me/chats/${chatId}/members`,
        { headers }
      );
  
      if (response?.data?.error?.code == 'Forbidden')
        return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}
        
      if (response?.data?.value) {
        const isExist = response.data.value.find((user:any) => user.userId == userId);
        if (isExist) {
          return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED}
        } else {
          return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}
        }
      }
  
      return { id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT };
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.error.code == 'Forbidden')
        return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}

      return { id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT };
    }
  }

  public checklistTeamExist(teams: any[]): Observable<any> {
    if (teams.length == 0) return of([]);

    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        const promiseAll: Promise<any>[] = []
        teams.forEach(async (team: any) => {
          promiseAll.push(this.checkTeamsExist(accessToken, team))
        })
        return Promise.all(promiseAll).then((res) => {
          const result: any = {};
          res.forEach((item) => {
            result[item.id] = item;
          })
          return result;
        });
      }
    ))
  }

  
  private async checkTeamsExist(accessToken: string, team: any) {
    const { id: teamId, channelIds } = team;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`,
        { headers }
      );
  
      if (response?.data?.error?.code == 'Forbidden') {
        const resChannel = channelIds.map((id: string) => ({
          id: id,
          status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER
        }))
        return { id: teamId, channelIds: resChannel };
      }
        
      if (response?.data?.value) {
        const result: any = {
          id: teamId,
          channelIds: []
        }
        channelIds.forEach((channelId: string) => {
          const isExist = response.data.value.findIndex((channel: any) => channel.id == channelId) > -1;
          if (isExist) {
            result.channelIds.push({id: channelId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED})
          } else {
            result.channelIds.push({id: channelId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT})
          }
        })
        return result;
      }
  
      const resChannel = channelIds.map((id: string) => ({
        id: id,
        status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT
      }))
      return { id: teamId, channelIds: resChannel };
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.error?.code == "Forbidden" ) {
        const resChannel = channelIds.map((id: string) => ({
          id: id,
          status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER
        }))
        return { id: teamId, channelIds: resChannel };
      }

      const resChannel = channelIds.map((id: string) => ({
        id: id,
        status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT
      }))
      return { id: teamId, channelIds: resChannel };
    } 
  }

  public sendGroup(groupId: string, Files: File[], messageGeneretor: any, emailList: { mail: string }[], listFileEvidence: File[] = []) {
    return this.getTokenForScope().pipe(
      switchMap(async (accessToken: string) => {
        return Promise.all([this.sendGroupMessage(
          accessToken,
          groupId,
          Files,
          messageGeneretor,
          emailList,
          listFileEvidence
        )]).then((res: any) => {
          console.log(res);
          if (res?.[0].response?.data?.error?.code == "Forbidden")
            return {
              type: AppConstant.MESSAGE_TYPE.WARNING,
              message: 'Send to group chat failed.'
            };

          return {
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            message: 'Send to group chat successfull.'
          }
        }).catch((error) => {
          console.log(error);
          return {
            type: AppConstant.MESSAGE_TYPE.WARNING,
            message: 'Send to group chat failed.'
          }
        });
      }
    ))
  }
  
  public sendChannel(teamId: string, channelId: string, files: File[], messageGeneretor: any, emailList: { mail: string }[], attachmentList: any[] = []) {
    return this.getTokenForScope().pipe(
      switchMap((accessToken: string) => {
        
        return Promise.all([this.sendChannelMessage(
          accessToken,
          teamId,
          channelId,
          files,
          messageGeneretor,
          emailList,
          attachmentList

        )]).then((res: any) => {
          console.log(res);
          if (res?.[0].response?.data?.error?.code == "Forbidden")
            return {
              type: AppConstant.MESSAGE_TYPE.WARNING,
              message: 'Send to channel failed.'
            };
          return {
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            message: 'Send to channel successfull.'
          }
        }).catch((error) => {
          console.log(error);
          return {
            type: AppConstant.MESSAGE_TYPE.WARNING,
            message: 'Send to channel failed.'
          }
        });
      }
    ))
  }

  private async prepareHostedContents(files: File[]): Promise<any[]> {
    const resizedImages = await Promise.all(
      files.map(async (file, index) => {
        const base64 = await AppHelper.ImageFunctions.resizeBase64Image(file, 400, 300); // Resize to 800x600
        const str = base64.split(";base64,");
        return {
          '@microsoft.graph.temporaryId': `${index + 1}`,
          "contentType": `${file.type}`,
          "contentBytes": `${str[1]}`,
        };
      })
    );
    return resizedImages;
  }
  private async sendGroupMessage(
    accessToken: string, 
    groupId: string, 
    files: File[] = [], 
    messageGeneretor: any,
    emailList: { mail: string }[],
    listFileEvidence: File[] = []
  ) {
    try {
      const client = this.getGraphClient(accessToken);
      const hostedContents = await this.prepareHostedContents(files);
  
      const members = await client.api(`/chats/${groupId}/members`).get();
  
      let emails = emailList.map((user: { mail: string }) => {
        let found: AadUserConversationMember = members?.value?.find((item: any) => item?.email?.trim().toLowerCase() === user.mail.trim().toLowerCase());
        if (found) {
          return found;
        } else {
          return {
            '@odata.context': null,
            businessPhones: [],
            displayName: user.mail,
            givenName: user.mail,
            id: null,
            jobTitle: null,
            mail: user.mail,
            mobilePhone: null,
            officeLocation: null,
            preferredLanguage: null,
            surname: null,
            userPrincipalName: user.mail,
          }
        }
      });
  
      // Build mentions array
      const mentions = emails
        .filter((user: any) => {
          return members?.value.some((member: any) => member.userId === user.userId);
        })
        .map((user: any, index: number) =>
          this.createMention(index, user.userId, user.displayName)
        );
  
      let memtionLists = '';
      emails.forEach((user: any) => {
        let found = mentions?.find((u: any) => user?.userId === u?.mentioned?.user?.id)
        if (found) {
          memtionLists += ` <at id=\"${found.id}\">${found.mentionText}</at>`
        } else {
          memtionLists += ` <at id=\"${null}\">${user.displayName}</at>`
        }
      });
  
      const contentMesage = messageGeneretor.isSaveResolveTab
        ? this.prepareTemplateMsgResolve(messageGeneretor.previewPDFPayload, files, memtionLists)
        : this.prepareTemplateMsgInitiate(messageGeneretor.previewPDFPayload, files, memtionLists);
  
      const requestBody: any = {
        body: {
          content: contentMesage,
          contentType: "html"
        },
        hostedContents: hostedContents,
        mentions: mentions.filter((user: any) => user.mentioned.user.id !== null),
        attachments: []
      };
  
      // Upload large attachments
      if(listFileEvidence.length) {
        const uploadPromises = listFileEvidence.map(async (attachment) => {
          try {
            const uploadUrl = await this.createUploadSession(accessToken, attachment);
            const fileId = await this.uploadFileInChunks(accessToken, uploadUrl, attachment);
            const fileLink = await this.createSharingLink(accessToken, fileId);
            requestBody.attachments.push({
              id: fileId,
              contentType: 'reference',
              contentUrl: fileLink,
            });
            requestBody.body.content += `<attachment id="${fileId}"></attachment>`;
          } catch (error) {
            console.error(`Failed to upload attachment ${attachment.name}:`, error);
          }
        });
        await Promise.all(uploadPromises);
      }
      
      const response = await client.api(`/chats/${groupId}/messages`).post(requestBody);
      return response;
    } catch (error) {
      return error;
    }
  }
  
  private async sendChannelMessage(
    accessToken: string,
    teamId: string,
    channelId: string,
    files: File[],
    messageGeneretor: any,
    emailList: { mail: string }[],
    attachmentList: any[] = []
  ) {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      const hostedContents = await this.prepareHostedContents(files);
  
      // Filter Member belong to group/channel
      const members: any = await axios.get(`https://graph.microsoft.com/v1.0/groups/${teamId}/members`, { headers });      
  
      let emails: User[] = emailList.map((user: { mail: string }) => {
        let found = members?.data?.value?.find((item: any) => item?.mail?.trim().toLowerCase() === user.mail.trim().toLowerCase());
        if (found) {
          return found;
        } else {
          return {
            '@odata.context': null,
            businessPhones: [],
            displayName: user.mail,
            givenName: user.mail,
            id: null,
            jobTitle: null,
            mail: user.mail,
            mobilePhone: null,
            officeLocation: null,
            preferredLanguage: null,
            surname: null,
            userPrincipalName: user.mail,
          }
        }
      })
      
      // Build mentions array
      const mentions = emails
      .filter((user: User) => members?.data?.value.some((member: any) => member.id === user.id))
      .map((user: any, index: number) => this.createMention(index, user.id, user.displayName));

      const memtionLists = emails.map((user: User) => {
        const found = mentions?.find((u: any) => user.id === u?.mentioned?.user?.id);
        return found ? ` <at id=\"${found.id}\">${found.mentionText}</at>` : ` <at id=\"${null}\">${user.displayName}</at>`;
      }).join('');
  
      const contentMesage = messageGeneretor.isSaveResolveTab
          ? this.prepareTemplateMsgResolve(messageGeneretor.previewPDFPayload, files, memtionLists)
          : this.prepareTemplateMsgInitiate(messageGeneretor.previewPDFPayload, files, memtionLists);
          
  
      let requestBody:any = {
        body: {
          content: contentMesage,
          contentType: "html"
        },
        hostedContents: hostedContents,
        mentions: mentions.filter((user: any) => user.mentioned.user.id !== null),
        attachments: []
      };
  
     // Upload large attachments concurrently
     if(attachmentList.length) {
    const uploadPromises = attachmentList.map(async (attachment) => {
      try {
        const uploadUrl = await this.createUploadSession(accessToken, attachment);
        const fileId = await this.uploadFileInChunks(accessToken, uploadUrl, attachment);
        const fileLink = await this.createSharingLink(accessToken, fileId);
        requestBody.attachments.push({
          id: fileId,
          contentType: 'reference',
          contentUrl: fileLink,
          name: attachment.name,
        });
        requestBody.body.content += `<attachment id="${fileId}"></attachment>`;
      } catch (error) {
        console.error(`Failed to upload attachment ${attachment.name}:`, error);
      }
    });

    await Promise.all(uploadPromises);
  }
  
      const response = await axios.post(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/messages`,
        requestBody,
        { headers }
      );
      
      return response;
    } catch (error) {
      return error;
    }
  }

  getColorFromFlag(flag: string): string {
    switch(flag) {
      case 'green':
        return 'rgba(50, 184, 119, 1)';
      case 'yellow':
        return 'rgba(217, 177, 0, 1)';
      case 'red':
        return 'rgba(194, 18, 40, 1)';
      case 'grey':
        return 'rgba(201, 207, 212, 1)';
      case 'orange':
        return 'rgba(247, 160, 64, 1)';
      case 'peach':
        return 'rgba(223, 122, 90, 1)';
      case 'maroon':
        return 'rgba(153, 91, 91, 1)';
      case 'brown':
        return 'rgba(135, 79, 17, 1)';
      case 'cyan':
        return 'rgba(73, 178, 211, 1)';
      case 'pink':
        return 'rgba(221, 150, 185, 1)';
      case 'navy':
        return 'rgba(106, 133, 206, 1)';
      case 'purple':
        return 'rgba(163, 107, 230, 1)';
      case 'lime':
        return 'rgba(145, 166, 43, 1)';
      case 'blue':
      default:
        return 'rgba(111, 158, 205, 1)';
    }
  }

  public prepareTemplateMsgInitiate(payload: any, srcImgs: File[] = [], memtionList: string) {
    const title = `${payload.alertEvent} - ${payload.alertFlag.toUpperCase()} | ${payload.well} | ${payload.wellbore} | ${payload.project}`
    const color = this.getColorFromFlag(payload.alertFlag);
    const userRole = AppConstant.ROLES[`${payload?.userDisciplineSave?.toUpperCase()}`]?.label || 'Engineer';
    const imgSection: string = srcImgs.map((_, index) => (
      `<span style="margin-top: 20px;">
        <img width="280" height="150" style="object-fit: cover;" src="../hostedContents/${index + 1}/$value"" />
      </span>
      <br>`
    )).join("");
    

    return `<span>
        <h2 style="padding-bottom: 20px; font-size: 18px;">VIRTUAL REMOTE OPERATIONS: ALERT</h2>
        <span>
          <b style="color: ${color};">${title}</b>
        </span>
        <br>
        <span>
          <b>Distribution:</b> <b style="color: ${ memtionList ? 'unset' : color };">${ memtionList ? memtionList : payload.alertFlag.toUpperCase()}</b>
        </span>
        <br>
        <span>
          <b>${userRole}:</b> <span>${payload.userNameSave} ${payload.disciplineName}</span>
        </span>
        <br>
        <span>
          <b>Remote Center:</b> <span>${payload.remoteCenter}</span>
        </span>
        <br>
        <span>
          <b>Description:</b> <span>${payload.eventDescription.replace(/\n/g, '<br>')}</span>
        </span>
        <br>
        <span>
          <b>Recommendation:</b> <span>${payload.recommendation.replace(/\n/g, '<br>')}</span>
        </span>
        <br>
        ${imgSection}
    </span>`
  }
  
  public prepareTemplateMsgResolve(payload: any, srcImgs: File[] = [], memtionList: string) {
    const title = `${payload.alertEvent} - ${payload.alertFlag.toUpperCase()} | ${payload.well} | ${payload.wellbore} | ${payload.project}`
    const color = this.getColorFromFlag(payload.alertFlag);
    const userRole = AppConstant.ROLES[`${payload?.userDisciplineSave?.toUpperCase()}`]?.label || 'Engineer';
    const imgSection: string = srcImgs.map((base64, index) => (
      `<span style="margin-top: 20px;">
          <img width="280" height="150" style="object-fit: cover;" src="../hostedContents/${index + 1}/$value"" />
        </span>
        `
    )).join("");

    return `<span>
        <h2 style="padding-bottom: 20px; font-size: 18px;">VIRTUAL REMOTE OPERATIONS: ALERT (RESOLUTION)</h2>
        <span>
          <b style="color: ${color};">${title}</b>
        </span>
        <br>
        <span>
          <b>Distribution:</b> <b style="color: ${ memtionList ? 'unset' : color };">${ memtionList ? memtionList : payload.alertFlag.toUpperCase()}</b>
        </span>
        <br>
        <span>
          <b>${userRole}:</b> <span>${payload.userNameSave} ${payload.disciplineName}</span>
        </span>
        <br>
        <span>
          <b>Remote Center:</b> <span>${payload.remoteCenter}</span>
        </span>
        <br>
        <span>
          <b>Resolution:</b> <span>${payload.resolutionDetail.replace(/\n/g, '<br>')}</span>
        </span>
        <br>
        <span>
          <b>Contact Method:</b> <span>${payload.contactMethod}</span>
        </span>
        <br>
        ${imgSection}
    </span>`
  }

  private createMention(id: number, userId: string, userName: string) {
    return {
      "id": id,
      "mentionText": userName,
      "mentioned": {
        "user": {
          "displayName": userName,
          "id": userId,
          "userIdentityType": "aadUser"
        }
      }
    }
  }

  private async createUploadSession(accessToken: string, attachment: File) {
    const client = this.getGraphClient(accessToken);
    const response = await client.api(`/me/drive/root:/${attachment.name}:/createUploadSession`)
      .post({
        item: {
          "@microsoft.graph.conflictBehavior": "rename",
          name: attachment.name
        }
      });
    return response.uploadUrl;
  }
  
  private async uploadFileInChunks(accessToken: string, uploadUrl: string, file: File): Promise<string> {
    const chunkSize = 4 * 1024 * 1024; // 4MB
    const fileSize = file.size;
    let start = 0;
    let end = chunkSize;
    let response;
  
    while (start < fileSize) {
      const chunk = file.slice(start, end);
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Range': `bytes ${start}-${end - 1 < fileSize ? end - 1 : fileSize - 1}/${fileSize}`,
      };
      response = await axios.put(uploadUrl, chunk, { headers });
      start = end;
      end = Math.min(start + chunkSize, fileSize);
    }
  
    return response?.data?.id;
  }

  private async createSharingLink(accessToken: string, fileId: string): Promise<string> {
    const client = this.getGraphClient(accessToken);
    const response = await client.api(`/me/drive/items/${fileId}/createLink`)
      .post({
        type: "view",
        scope: "organization"
      });
    return response.link.webUrl;
  }
}