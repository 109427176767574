import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Subject,
  catchError,
  combineLatest,
  switchMap,
  takeUntil,
  forkJoin,
  mergeMap,
} from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { FileUpload } from 'primeng/fileupload';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { IConfirmDialog, IDropdownSelection } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import _ from 'lodash';

@Component({
  selector: 'app-home-form-rig',
  templateUrl: './home-form-rig.component.html',
  styleUrls: ['./home-form-rig.component.scss'],
})
export class HomeFormRigComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  displayFormRig: boolean = false;

  @Input()
  rigEdit: any;

  @Input()
  rigJournalEdit: any;

  @Input() rigEditId: string = '';
  @Input() rigJournalEditId: string = '';

  @Input() projectName: any;

  @Output()
  hideFormRig: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  requestReloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  counterRemoteCenter: number = 0;
  counterMailBox: number = 0;

  selectedNodeMailBox: any[] = [];
  selectedNodeStringMailBox: string = '';

  valueUploadRoutingDiagramPDF: number = 0;
  valueUploadprotocolPDF: number = 0;

  @ViewChild('uploadRoutingDiagramPDF') uploadRoutingDiagramPDF?: FileUpload;
  @ViewChild('progressUploadRoutingDiagramPDF')
  progressUploadRoutingDiagramPDF?: ElementRef;

  @ViewChild('uploadProtocolPDF') uploadProtocolPDF?: FileUpload;
  @ViewChild('progressUploadProtocolPDF')
  progressUploadProtocolPDF?: ElementRef;

  // Max File Size Upload
  maxFileSizeUpload: number = 10000000;
  totalFileSizeUpload: number = 0;
  currentDiagramSizeUpload: number = 0;
  currentProtocolSizeUpload: number = 0;

  //tracking upload to enable ADD button
  trackingUpload = [false, false];

  languageList: IDropdownSelection[] = JSON.parse(JSON.stringify(AppConstant.LANGUAGE_TEMPLATE));

  public fieldNameKeys = {
    projectId: 'projectId',
    projectName: 'projectName',
    rigId: 'rigId',
    rigJournalId: 'rigJournalId',
    rigName: 'rigName',
    language: 'language',
    remoteCenter: 'remoteCenter',
    funtionalMailbox: 'funtionalMailbox',
    routingDiagramPDF: 'routingDiagram',
    protocolPDF: 'protocol',
  };

  public formRigGroup: FormGroup = this._builder.group({
    [this.fieldNameKeys.projectName]: [''],
    [this.fieldNameKeys.rigId]: [''],
    [this.fieldNameKeys.rigName]: [''],
    [this.fieldNameKeys.remoteCenter]: [''],
    [this.fieldNameKeys.language]: [''],
    [this.fieldNameKeys.funtionalMailbox]: [''],
    [this.fieldNameKeys.routingDiagramPDF]: [''],
    [this.fieldNameKeys.protocolPDF]: [''],
  });

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    rigNameValidator: 'Please enter the rig name between 2 and 30 characters',
  };

  private limitCharacterRegex_1: string = `^[0-9A-Za-z!@\. ;:'"?-]{2,30}$`;
  private nonSpaceStartEndRegex = `^[^\s]+(\s+[^\s]+)*$`;

  remoteCenters: any[] = [];
  languages: any[] = [];
  mailBoxes: any[] = [];

  fileLabelDataRoutingUrl: string = 'Browse file';
  fileLabelCommunicateProtocolUrl: string = 'Browse file';

  filtersLoaded?: Promise<boolean>;
  settingDataFormRig$ = new Subject();
  flowEditRig$ = new Subject();

  showCancelButtonPDF1: boolean = false;
  showCancelButtonPDF2: boolean = false;

  lastPDF1Link: string = '';
  lastPDF2Link: string = '';

  isViewer: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  
  constructor(
    private _builder: FormBuilder,
    private _userInfoService: UserInfoService,
    private _homeService: HomeService,
    private _notificationService: NotificationService,
    private _distributionService: DistributionService, // Temporarily
    private _confirmService: ConfirmDialogService,
    private _cd: ChangeDetectorRef,
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;
    // Since this is a popup, there is definitely User data. So we can get form data with .getValue()
    this.isViewer = this._userInfoService.userSubject.getValue().role === AppConstant.ROLES.VIEWER.label;

    // Setting Rig Data
    this.settingDataFormRig$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() =>
          forkJoin([
            this._userInfoService.getRemoteCenter(),
            this._homeService.getRigJournalbyId(this.rigJournalEditId),
          ])
        )
      )
      .subscribe(([remote, rigJournal]: any) => {
        if (remote && rigJournal) {
          this.rigJournalEdit = rigJournal.data;
          this.rigJournalEdit.remoteCentersData = []
          this.rigJournalEdit.mailBoxesData = []
          // Setting data for Remote Center and MailBox
          this.remoteCenters = remote.data.map((item: any) => {
            const childrendMailbox = (item.mailboxList as any[])
              .filter((mailbox: any) => mailbox.isActive)
              .map((mailbox: any) => ({
                label: mailbox.mailboxName,
                data: mailbox.mailboxId,
                disciplineId: mailbox.disciplineId,
                mailboxId: mailbox.mailboxId,
                mailboxName: mailbox.mailboxName,
                remoteCenterId: mailbox.remoteCenterId,
              }));
            this.mailBoxes.push({
              label: item.remoteCenterName,
              data: item.remoteCenterId,
              styleClass: childrendMailbox.length ? 'parent' : 'nonChild',
              selectable: false,
              id: item.remoteCenterId,
              children: childrendMailbox,
            });

            // Setting data for Selected Remote Centers
              this.rigJournalEdit.remoteCenterList?.map((remoteCenter: any) => {
                if (remoteCenter.remoteCenterId === item.remoteCenterId) {
                   this.rigJournalEdit.remoteCentersData?.push(remoteCenter);
                }
              })
            // Setting data for Selected MailBoxes
            this.rigJournalEdit.mailboxList.forEach((mailbox: any) => {
              const element = childrendMailbox.find((child: any) => child.mailboxId === mailbox.mailboxId);
              if (element) {
                this.rigJournalEdit.mailBoxesData?.push(element);
              }
            });
            
            return {
              remoteCenterId: item.remoteCenterId,
              remoteCenterName: item.remoteCenterName,
            };
          });

          // Setting Rig Data
          this.buildFormEdit(this.rigJournalEdit, this.projectName);
          this.loadNameOfFiles(
            this.rigJournalEdit.dataRoutingUrl,
            this.rigJournalEdit.communicateProtocolUrl
          );

          this.isLoading = false;
          this._cd.detectChanges();
        }
      });
    this.settingDataFormRig$.next(null);
  }

  buildFormEmpty() {
    this.formRigGroup = this._builder.group({
      [this.fieldNameKeys.projectName]: [''],
      [this.fieldNameKeys.rigId]: [''],
      [this.fieldNameKeys.rigJournalId]: [''],
      [this.fieldNameKeys.rigName]: [''],
      [this.fieldNameKeys.remoteCenter]: [''],
      [this.fieldNameKeys.funtionalMailbox]: [''],
      [this.fieldNameKeys.routingDiagramPDF]: [''],
      [this.fieldNameKeys.protocolPDF]: [''],
    });
  }

  buildFormEdit(rigJournal: any, projectName: string) {
    // Setting counter for dropdown
    this.counterRemoteCenter = rigJournal.remoteCentersData?.length || 0;
    this.counterMailBox = rigJournal.mailBoxesData?.length || 0;

    const selectedLanguage = this.languageList.find(
      (language: IDropdownSelection) => rigJournal?.language === language.id
    ) || this.languageList[0];

    this.formRigGroup = this._builder.group({
      [this.fieldNameKeys.projectName]: [
        { value: projectName || 'Updating Project Name ....', disabled: true },
      ],
      [this.fieldNameKeys.rigId]: [rigJournal.rig.rigId || ''],
      [this.fieldNameKeys.rigJournalId]: [rigJournal.rigJournalId || ''],
      [this.fieldNameKeys.rigName]: [
        { value: rigJournal.rig.rigName, disabled: true },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.nonSpaceStartEndRegex,
            'rigName',
            'whitespace'
          ),
        ],
      ],
      [this.fieldNameKeys.remoteCenter]: [
        rigJournal.remoteCenterList,
        Validators.required,
      ],
      [this.fieldNameKeys.language]: [selectedLanguage, Validators.required],
      [this.fieldNameKeys.funtionalMailbox]: [rigJournal.mailBoxesData],
      [this.fieldNameKeys.routingDiagramPDF]: [rigJournal.dataRoutingUrl],
      [this.fieldNameKeys.protocolPDF]: [rigJournal.communicateProtocolUrl],
    });

    // Setting label field Upload
    if (rigJournal.dataRoutingUrl) {
      this.fileLabelDataRoutingUrl = AppHelper.UtileFunctions.getFileNameFromUrl(rigJournal.dataRoutingUrl);
      this.formRigGroup.get(this.fieldNameKeys.routingDiagramPDF)?.setValue('HaveValuePDF');
    }

    if (rigJournal.communicateProtocolUrl) {
      this.fileLabelCommunicateProtocolUrl = AppHelper.UtileFunctions.getFileNameFromUrl(rigJournal.communicateProtocolUrl);
      this.formRigGroup.get(this.fieldNameKeys.protocolPDF)?.setValue('HaveValuePDF');
    }
  }

  loadNameOfFiles(
    linkDataRoutingUrl?: string,
    linkCommunicateProtocolUrl?: string
  ) {
    this.lastPDF1Link = linkDataRoutingUrl || '';
    this.lastPDF2Link = linkCommunicateProtocolUrl || '';

    this.fileLabelDataRoutingUrl = linkDataRoutingUrl
      ? AppHelper.UtileFunctions.getFileNameFromUrl(linkDataRoutingUrl)
      : 'Browse file';

    this.fileLabelCommunicateProtocolUrl = linkCommunicateProtocolUrl
      ? AppHelper.UtileFunctions.getFileNameFromUrl(linkCommunicateProtocolUrl)
      : 'Browse file';

    if (linkDataRoutingUrl) {
      const nameFilePDF1 = this.fileLabelDataRoutingUrl;
      this.uploadRoutingDiagramPDF!._files = [
        new File([new Blob()], nameFilePDF1, { type: 'application/pdf' }),
      ];
      this.formRigGroup.get('routingDiagramPDF')?.setValue(this.uploadRoutingDiagramPDF!._files[0].size);
      this.getRefresh('routingDiagramPDF');
      this.showCancelButtonPDF1 = true;
    }

    if (linkCommunicateProtocolUrl) {
      const nameFileSpec = this.fileLabelCommunicateProtocolUrl;
      this.uploadProtocolPDF!._files = [
        new File([new Blob()], nameFileSpec, { type: 'application/pdf' }),
      ];
      this.formRigGroup.get('protocolPDF')?.setValue(this.uploadProtocolPDF!._files[0].size);
      this.getRefresh('protocolPDF');
      this.showCancelButtonPDF2 = true;
    }
  }

  countItemSelected(e: any, controlName: string) {
    let justPickId = e.itemValue.remoteCenterId;
    let mailBoxValue = this.formRigGroup.controls['funtionalMailbox'].value;
    let controlValue = this.formRigGroup.controls[controlName].value;
    let findInRemote = controlValue.find(
      (remote: any) => remote.remoteCenterId === justPickId
    );

    if (findInRemote === undefined) {
      mailBoxValue = mailBoxValue.filter(
        (mailBox: any) => mailBox.remoteCenterId !== justPickId
      );
      this.formRigGroup
        .get(this.fieldNameKeys.funtionalMailbox)
        ?.setValue(mailBoxValue);

      this.counterMailBox = this.formRigGroup.get(
        this.fieldNameKeys.funtionalMailbox
      )!.value!.length;
    }

    if (controlValue) {
      switch (controlName) {
        case 'remoteCenter':
          this.counterRemoteCenter = controlValue.length;
          break;
      }
    }
  }

  clearItemSelected() {
    this.counterRemoteCenter = 0;

    this.formRigGroup.get(this.fieldNameKeys.funtionalMailbox)!.setValue([]);
    this.counterMailBox = 0;
  }

  submitRig(formValue: any) {
    this.isLoading = true;

    let scopePayload = {
      mailboxList: formValue.funtionalMailbox
        ? formValue.funtionalMailbox.map((mailbox: any) => {
            return {
              mailboxId: mailbox.mailboxId,
            };
          })
        : [],
      remoteCenterList: formValue.remoteCenter.map((remote: any) => {
        return { remoteCenterId: remote.remoteCenterId };
      }),
    };

    let payload = {
      rigJournalId: formValue.rigJournalId,
      rigId: formValue.rigId,
      language: formValue?.language?.id,
      rigRoutingDiagramPDF: this.lastPDF1Link
        ? this.lastPDF1Link
        : this.uploadRoutingDiagramPDF!._files[0],
      rigProtocolPDF: this.lastPDF2Link
        ? this.lastPDF2Link
        : this.uploadProtocolPDF!._files[0],
    };

    let rigRemoteListName: string = '';
    if (formValue.remoteCenter.length) {
      rigRemoteListName = formValue.remoteCenter
        .map((r: any) => r.remoteCenterName)
        .join(', ');
    }

    this.flowEditRig$
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$),
        mergeMap((id) =>
          combineLatest([
            this._homeService.addScopeRigJournal(
              scopePayload,
              formValue.rigJournalId
            ),
            this._homeService.addFileRigJournal(
              payload.rigJournalId,
              payload.rigRoutingDiagramPDF,
              payload.rigProtocolPDF,
              payload.language
            ),
          ])
        )
      )
      .subscribe({
        next: ([scope, rigFile]: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Update Rig',
            content: 'The rig was updated successfully!',
          });
          this.isLoading = false;
          this.requestReloadPage.emit(true);

          this._distributionService.setFocusRigCard({
            updateType: 'RigTile',
            rigJournalId: payload.rigJournalId,
            rigFile: rigFile.data,
            rigRemoteListName: rigRemoteListName,
            mailboxList: formValue.funtionalMailbox ? formValue.funtionalMailbox : [],
            language: payload.language
          });
        },
        error: (error) => {
          console.error(error);
          error &&
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Update rig failed',
              content: error?.message || error,
            });
          this.isLoading = false;
        },
      });
    this.flowEditRig$.next(null);
  }
  closeFormDialog() {
    if (this.formRigGroup.touched || !this.formRigGroup.pristine) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    } else {
      this.hideFormRig.emit(!this.displayFormRig);
    }
  }

  closeDialogForm() {
    this.hideFormRig.emit(!this.displayFormRig);
  }

  onSelect_Diagram(e: any) {
    let errorUpload = this.uploadRoutingDiagramPDF?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      // handle button cancel when choose invalid type
      this.onClear_Diagram();
      return;
    }

    this.totalFileSizeUpload =
      this.currentDiagramSizeUpload +
      this.uploadRoutingDiagramPDF!._files[0].size;

    if (this.totalFileSizeUpload > this.maxFileSizeUpload) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content:
          'Maximum total upload size of Routing Diagram and Communication Protocol is 10 MB.',
      });
      return;
    }

    // if not error we update size for variable
    this.currentDiagramSizeUpload =
      this.uploadRoutingDiagramPDF!._files[0].size;

    // add value for hidden input to clear message
    this.formRigGroup
      .get(this.fieldNameKeys.routingDiagramPDF)
      ?.setValue('HaveValuePDF');

    this.progressUploadRoutingDiagramPDF!.nativeElement.style.display = 'block';

    if (this.valueUploadRoutingDiagramPDF === 100) {
      this.valueUploadRoutingDiagramPDF = 0;
      this.trackingUpload[0] = false;
    }

    this.uploadRoutingDiagramPDF!.showCancelButton = true;

    // pipe letter here
    this.uploadRoutingDiagramPDF!.chooseLabel =
      AppHelper.StringFunctions.shortenFileName(
        this.uploadRoutingDiagramPDF!._files[0].name,
        12,
        6
      );

    this.fileLabelDataRoutingUrl = this.uploadRoutingDiagramPDF!._files[0].name;

    this.lastPDF1Link = '';

    let interval = setInterval(() => {
      this.valueUploadRoutingDiagramPDF =
        this.valueUploadRoutingDiagramPDF +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.valueUploadRoutingDiagramPDF >= 100) {
        this.valueUploadRoutingDiagramPDF = 100;
        clearInterval(interval);
        this.progressUploadRoutingDiagramPDF!.nativeElement.style.display =
          'none';
        this.trackingUpload[0] = true;
      }
    }, 500);
  }
  onClear_Diagram(e?: any) {
    this.lastPDF1Link = '';

    this.fileLabelDataRoutingUrl = 'Browse file';

    this.uploadRoutingDiagramPDF!.chooseLabel = 'Browse file';
    this.progressUploadRoutingDiagramPDF!.nativeElement.style.display = 'none';
    this.uploadRoutingDiagramPDF!.showCancelButton = false;

    this.formRigGroup.get(this.fieldNameKeys.routingDiagramPDF)?.patchValue('');
    this.getRefresh(this.fieldNameKeys.routingDiagramPDF);

    // reset current upload size
    this.currentDiagramSizeUpload = 0;
  }

  onSelect_Protocol(e: any) {
    let errorUpload = this.uploadProtocolPDF?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      // handle button cancel when choose invalid type
      this.onClear_Protocol();
      return;
    }

    this.totalFileSizeUpload =
      this.currentProtocolSizeUpload + this.uploadProtocolPDF!._files[0].size;

    if (this.totalFileSizeUpload > this.maxFileSizeUpload) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content:
          'Maximum total upload size of Routing Diagram and Communication Protocol is 10 MB.',
      });
      return;
    }

    // if not error we update size for variable
    this.currentProtocolSizeUpload = this.uploadProtocolPDF!._files[0].size;

    // add value for hidden input to clear message
    this.formRigGroup
      .get(this.fieldNameKeys.protocolPDF)
      ?.setValue('HaveValuePDF');

    this.progressUploadProtocolPDF!.nativeElement.style.display = 'block';

    if (this.valueUploadprotocolPDF === 100) {
      this.valueUploadprotocolPDF = 0;
      this.trackingUpload[1] = false;
    }

    this.uploadProtocolPDF!.showCancelButton = true;

    // pipe letter here
    this.uploadProtocolPDF!.chooseLabel =
      AppHelper.StringFunctions.shortenFileName(
        this.uploadProtocolPDF!._files[0].name,
        12,
        6
      );

    this.fileLabelCommunicateProtocolUrl =
      this.uploadProtocolPDF!._files[0].name;

    this.lastPDF2Link = '';

    let interval = setInterval(() => {
      this.valueUploadprotocolPDF =
        this.valueUploadprotocolPDF +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.valueUploadprotocolPDF >= 100) {
        this.valueUploadprotocolPDF = 100;
        // this.messageService.add({
        //   severity: 'info',
        //   summary: 'Success',
        //   detail: 'Process Completed',
        // });
        clearInterval(interval);
        this.progressUploadProtocolPDF!.nativeElement.style.display = 'none';
        this.trackingUpload[1] = true;
      }
    }, 500);
  }
  onClear_Protocol(e?: any) {
    this.lastPDF2Link = '';

    this.fileLabelCommunicateProtocolUrl = 'Browse file';

    this.uploadProtocolPDF!.chooseLabel = 'Browse file';
    this.progressUploadProtocolPDF!.nativeElement.style.display = 'none';
    this.uploadProtocolPDF!.showCancelButton = false;

    this.formRigGroup.get(this.fieldNameKeys.protocolPDF)?.patchValue('');
    this.getRefresh(this.fieldNameKeys.protocolPDF);

    // reset current upload size
    this.currentProtocolSizeUpload = 0;
  }

  onOptionsSelectedMailBox(event: any) {
    let remoteValue = this.formRigGroup.controls['remoteCenter'].value;

    let newRemote = this.remoteCenters.find(
      (remote: any) => remote.remoteCenterName === event.node.parent.label
    );

    if (remoteValue) {
      let isExist = remoteValue.find(
        (remote: any) => remote.remoteCenterId === newRemote.remoteCenterId
      );
      if (!isExist) remoteValue.push(newRemote);
    } else {
      remoteValue = [];
      remoteValue.push(newRemote);
    }

    if (remoteValue.length) {
      this.formRigGroup
        .get(this.fieldNameKeys.remoteCenter)!
        .setValue(remoteValue);

      this.counterRemoteCenter = this.formRigGroup.get(
        this.fieldNameKeys.remoteCenter
      )!.value!.length;
    }

    this.counterMailBox = this.formRigGroup.get(
      this.fieldNameKeys.funtionalMailbox
    )!.value!.length;
  }

  onOptionsUnSelectedMailBox(event: any) {
    this.counterMailBox = this.formRigGroup.get(
      this.fieldNameKeys.funtionalMailbox
    )!.value!.length;
  }
  onClearMailBox(event: any) {
    this.selectedNodeMailBox = [];
    this.selectedNodeStringMailBox = '';
    this.counterMailBox = 0;
  }

  getRefresh(controlName: string) {
    const control = this.formRigGroup.get(controlName);
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  // This function using for custom validators
  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;

        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        const reg = new RegExp(regex);

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        if (reg.test(currentValue ? currentValue.trim() : '')) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formRigGroup.get(controlName);

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formRigGroup.get(controlName)?.value;

    const errorObj = this.formRigGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  // Because when dont have any chareacter another error message of required will show
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }
  onDestroy(): void {}
}
