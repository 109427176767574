import { AppConstant } from 'src/app/shared/utilities/app.constant';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalService,
} from '@azure/msal-angular';
import { Subject, of, switchMap, take, finalize, filter } from 'rxjs';
import { AzureAdService } from '../../services/azure-ad.service';
import { UserInfoService } from '../../services/user-info.service';
import { AzureBlobService } from '../../services/azure-blob.service';
import { AppHelper } from '../../utilities/app.helper';
import { NotificationService } from '../../services/notification.service';
import { InterventionService } from '../../services/intervention.service';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import jpack from '../../../../../package.json';
import { WebSocketService } from '../../services/web-socket.service';
import { NotificationEventService } from '../../services/notification-event.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../../assets/styles/share/_header.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  // To set active/inactive color for User Icon
  @Input()
  isDisplaySidebarUserInfomation: any;

  AppConstant = AppConstant;

  isUserLoggedIn = false;
  isTester = false;
  role: string = '';

  isAuthenticated = false;
  activeUser: string | undefined = 'Unknown user';
  userEmail: string | undefined = 'Unknown user';
  currentUser: any;

  private unsubscribe = new Subject<void>();

  mennuClose: boolean = false;
  notificationPanelExpanded: boolean = false; 
  
  tutorialPanelExpanded: boolean = false; 

  @Output() expandMenu = new EventEmitter<boolean>();
  @Output() requestReloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  roles: any[] = [];
  selectedRole: any = null;
  settingDataFormUser$ = new Subject();
  showSwitchRole: boolean = false;

  APPLICATION_VERSION: string = `${AppConstant.APPLICATION_NAME} v0.0.0`;

  eventsCount: number = 0; 

  constructor(
    private _router: Router,
    public _msalService: MsalService,
    public _azureAdService: AzureAdService,
    private _userInfoService: UserInfoService,
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
    private _collapseLayoutService: CollapseLayoutService,
    private _io: WebSocketService,
    private _notificationEventService: NotificationEventService,
    private _homeService: HomeService,
    private _interventionService: InterventionService,
  ) {}

  ngOnInit() {
    this.roles = JSON.parse(JSON.stringify(AppConstant.ROLES_TEAM));
    // [...AppConstant.ROLES_TEAM];
    this.roles[0].children = [];
    this.roles[0].children.push({
      label: 'TEST',
      data: null,
      styleClass: 'lowestChild',
      leaf: false,
      parent: 'Engineer',
    });
    this.roles.push(AppConstant.ROLES.VIEWER.value);
    this.roles.push(AppConstant.ROLES.MANAGER.value);
    this.roles.push(AppConstant.ROLES.ADMIN.value);
    if (this.roles[0].children.length === 0) {
      // Remove Engineer Role from select dropdown
      this.roles.shift();
    }

    this._userInfoService.userSubject$
      .pipe(
        filter((userObject: any) => !!userObject),
        take(1)
      )
      .subscribe((userResponse: any) => {
        this.currentUser = userResponse;
        this.listenSocketEvents();
        this.showSwitchRole =
          userResponse.role === AppConstant.ROLES.ADMIN.label;
        this.settingDataFormUser$.next(null);
        this.isTester =
          this._userInfoService.userSubject.getValue().tag === 'tester';
        this.role = this._userInfoService.userSubject.getValue().role;

        if (this.roles.length) {
          // Auto select last role - Admin

          switch (this.role) {
            case AppConstant.ROLES.ADMIN.label:
              this.selectedRole = this.roles[3];
              break;

            case AppConstant.ROLES.MANAGER.label:
              this.selectedRole = this.roles[2];
              break;

            case AppConstant.ROLES.VIEWER.label:
              this.selectedRole = this.roles[1];
              break;

            case AppConstant.ROLES.ENGINEER.label:
              this.selectedRole = this.roles[0];
              break;

            default:
              this.selectedRole = null;
              break;
          }
        }
      });
    this.APPLICATION_VERSION = `${AppConstant.APPLICATION_NAME} ${jpack.version}`;

    this.listenUnreadCountChanged();

    this._collapseLayoutService.toggleNotificationPanel$.subscribe(flag => this.notificationPanelExpanded = flag);
    this._notificationEventService.countUnreadEvents().subscribe((data: any) => {
      this.eventsCount = data?.count || 0;
    });
    this._collapseLayoutService.toggleTutorialPanel$.subscribe(flag => this.tutorialPanelExpanded = flag);
  }

  listenSocketEvents() {
    this._io.listen(`notification_events/${this.currentUser.id}/unread_count`).subscribe((count: any) => {
      this.eventsCount = count || 0;
    });
  }

  listenUnreadCountChanged() {
    this._notificationEventService.unreadCountChanged$.subscribe((count: any) => {
      this.eventsCount = this.eventsCount + count;
    });
  }

  onNodeSelect() {
    this.isLoading = true;
    const label: string = this.selectedRole.label || null;
    let role = '';
    switch (label) {
      case AppConstant.ROLES.ADMIN.label:
        role = AppConstant.ROLES.ADMIN.label;
        break;

      case AppConstant.ROLES.MANAGER.label:
        role = AppConstant.ROLES.MANAGER.label;
        break;

      case AppConstant.ROLES.VIEWER.label:
        role = AppConstant.ROLES.VIEWER.label;
        break;

      default:
        role = AppConstant.ROLES.ENGINEER.label;
        break;
    }

    const payload = {
      role,
    };
    this._userInfoService.changeRoleTester(payload).subscribe({
      next: (res: any) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: 'Change role User',
          content: 'User role has been changed successfully!',
        });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      error: (error: any) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Change role User',
          content: error?.message || error,
        });
        this.isLoading = false;
      },
    });
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  userPanel() {
    this._userInfoService.isShowUserPanel
      .pipe(take(1))
      .subscribe((currentStatus) => {
        this._userInfoService.changeShowUserPanelStatus(!currentStatus);
        if (
          this._router.url === '/user/team-management' &&
          this._userInfoService.requestReloadUserManagement.getValue() === true
        ) {
          // pass request reload to Team Management
          this._userInfoService.reloadUserManagement$.next(true);
        }

        if (
          this._router.url.includes('/user/team-management/') &&
          this._userInfoService.requestReloadUserPermission.getValue() === true
        ) {
          // pass request reload to Team Management
          this._userInfoService.reloadUserPermission$.next(true);
        }
      });
  }

  downloadBlankDistributionList() {
    this.isLoading = true;

    this._blobService.getFileByUrl('https://hpmvrodev2scusstapp.blob.core.windows.net/material/template_distribution.xlsx').pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({ 
      next: (blob: Blob) => {
        AppHelper.UtileFunctions.clickDownloadDocumentWithName(
          blob,
          `${AppConstant.APPLICATION_NAME} Distribution Lists Blank Template.xlsx`
        );
      },
      error: (error) => {
        console.error('Error: ' + error);
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Loading Document',
          content: 'This document is no longer available, please try again',
        });
      },
    });
  }

  changeIconMenu() {
    this.mennuClose = !this.mennuClose;
    this.expandMenu.emit(this.mennuClose);
  }

  onToggleNotificationPanel() {
    this.notificationPanelExpanded = !this.notificationPanelExpanded;
    this._collapseLayoutService.toggleNotificationPanel(this.notificationPanelExpanded);
  }

  onToggleTutorialPanel() {
    this.tutorialPanelExpanded = !this.tutorialPanelExpanded;
    this._collapseLayoutService.toggleTutorialPanel(this.tutorialPanelExpanded);
  }

  redirectTo(endPoint: string) {
    switch (endPoint) {
      case 'powerbi':
        window.open(AppConstant.POWBER_BI, '_blank');
        break;
      default:
        this._interventionService.resetLogFilter();
        
        // Set Default Filter -- Without CONSTANTS
        this._homeService.setPayloadFilterDefault();
        
        this._collapseLayoutService.updateCollapseLayout(true);
        this._router.navigate([`${endPoint}`])

        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();

    // stop listening to socket events 
    this._io.unlisten(`notification_events/${this.currentUser.id}/unread_count`);
  }
}
