<div class="comment-video">
  <div class="flex flex-row justify-content-between header-comment-video">
    <div class="flex flex-row user-info">
      <!-- <p-avatar
        [image]="this.avatarUserUrl || '../../../assets/images/no-avatar.jpg'"
        styleClass="mr-2"
        shape="circle"
      ></p-avatar> -->

        <ng-container *ngIf="!comment.user?.avatar">
            <div class="avatar">
              <img
                src="../../../../assets/images/no-avatar.jpg"
                [alt]="'Avatar Image of' + comment?.user?.displayName"
              />
            </div>
        </ng-container>
        <ng-container *ngIf="comment.user?.avatar">
          <div class="avatar">
            <img
              appBlobImage
              [imageUrl]="comment.user?.avatar"
              [alt]="'Avatar Image of' + comment?.user?.displayName"
            />
          </div>
        </ng-container>
      <p class="flex align-items-center justify-content-center h-full">
        {{ comment.user.displayName }}
      </p>
    </div>
    <div class="flex align-items-center justify-content-center">
      <p class="h-full comment-date">
        {{ transformDate(comment.createdAt) }}
      </p>
      <div class="option-comment" *ngIf="isAdmin || isViewer">
        <button
          type="button"
          pButton
          icon="pi pi-ellipsis-v"
          [ngClass]="{ active: isMenuShown }"
          (click)="menu.toggle($event)"
        ></button>
      </div>
    </div>
  </div>
  <p
    class="text-comment-video"
    *ngIf="comment.comment"
    appExpandableContent
    [userId]="userId"
    [content]="comment.comment"
    [sizeContent]="150"
  >
    {{ comment.comment }}
  </p>
</div>
<p-menu
  #menu
  [popup]="true"
  [model]="items"
  (onShow)="onMenuShow()"
  (onHide)="onMenuHide()"
  styleClass="comment-video-option"
  appendTo="body"
></p-menu>
